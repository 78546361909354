import React, { useState, useEffect } from "react";
import InstructorSidebar from "../sidebar";
import { Footer } from "../../footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import moment from "moment/moment";
// import "./dashbord.css"

export default function FoundMissingDashboard() {

    const userData = JSON.parse(localStorage.getItem('userData'));

    const [FoundMissingItemData, setFoundMissingItemData] = useState('');
    console.log(FoundMissingItemData, "FoundMissingItemData");

    useEffect(() => {
        FoundMissingItemListURL();
    }, []);

    const FoundMissingItemListURL = () => {
        axios.get(`${process.env.React_App_Url}/api/v1/client/FoundList?page=0&limit=10&serach=`, {
            headers: {
                authorization: `Bearer ${userData?.accessToken}`,
            },
        })
            .then((response) => {
                console.log(response, "asdasdasd");
                setFoundMissingItemData(response?.data?.data);
                toast.success(response.data, { position: 'top-right' });
            })
            .catch((error) => {
                console.log(error, "sdaassda");
                toast.error(error.response.data);
            });
    };

    return (
        <div className="main-wrapper">
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <InstructorSidebar activeMenu="FoundAndMissingItem" />
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sell-course-head comman-space">
                                        <h3>Found/Missing Item</h3>
                                    </div>
                                    <div className="settings-widget">
                                        <div className="settings-inner-blk p-0">
                                            <div className="row mx-4">
                                                {FoundMissingItemData?.data?.length <= 0 && <strong>No Records Found</strong>}
                                                {FoundMissingItemData?.data?.map((items, index) => {
                                                    return (
                                                        <>
                                                            <div key={index} className="col-md-12">
                                                                <div className="row card mt-3">
                                                                    <div className="row p-3">
                                                                        <div className="col-xs-12 col-md-2 col-item">
                                                                            <strong>Date</strong>
                                                                            <p>{moment(items?.booking_date_from).format("YYYY-MM-DD")}</p>
                                                                        </div>
                                                                        <div className="col-xs-12 col-md-2 col-item">
                                                                            <strong>Client</strong>
                                                                            <p>{moment(items?.booking_date_to).format("YYYY-MM-DD")}</p>
                                                                        </div>
                                                                        <div className="col-xs-12 col-md-2 col-item">
                                                                            <strong>Unit</strong>
                                                                            <p>{items?.unit}</p>
                                                                        </div>
                                                                        <div className="col-xs-12 col-md-2 col-item">
                                                                            <strong>Building Name</strong>
                                                                            <p>{items?.building_name}</p>
                                                                        </div>
                                                                        <div className="col-xs-12 col-md-2 col-item">
                                                                            <strong>Region</strong>
                                                                            <p>{items?.region}</p>
                                                                        </div>
                                                                        <div className="col-xs-12 col-md-2 col-item">
                                                                            <strong>Item Found</strong>
                                                                            <p>{items?.item_found}</p>
                                                                        </div>
                                                                        <div className="col-xs-12 col-md-2 col-item">
                                                                            <strong>Staff Name</strong>
                                                                            <p>{items?.staff_name}</p>
                                                                        </div>
                                                                        <div className="col-xs-12 col-md-2 col-item">
                                                                            <strong>Received By</strong>
                                                                            <p>{items?.received_by}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

import React, { useEffect, useState } from "react";
import Header from "../header/index-two";
import { Footer } from "../footer";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const NewBookingForm = () => {
    const style = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "white",
            width: "100%",
            height: "40px",
            color: "black",
            minHeight: "40px",
            paddingLeft: "5px",
            // This line disable the blue border
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: state.isSelected ? "0" : "10px",
            fontSize: "14px",
            "&:hover": {
                cursor: "pointer",
            },
            outline: "none",
        }),
        menu: (base) => ({ ...base, marginTop: "0px" }),
        menuList: (base) => ({ ...base, padding: "0" }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#FFDEDA" : "white",
            color: "black",
            fontSize: "14px",
            "&:hover": {
                backgroundColor: "#FFDEDA",
                // #dddddd
            },
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: "black",
            transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
            transition: "250ms",
        }),
    };

    const userData = JSON.parse(localStorage.getItem('userData'));

    const [loading, setLoading] = useState(false);

    const location = useLocation();
    console.log(location, "sdbhvjbcshjdsj");

    const ServiceUUID = location?.state?.data1; // ST-8CB12A96 , 
    const SubServices = location?.state?.data?.uuid; // SST-55871890 , 

    console.log(ServiceUUID, SubServices, "hjfjdhfkjdsjd")

    const [datevalue, setDateValue] = useState('');
    const [timevalue, setTimeValue] = useState('');
    const [addressValue, setAddressValue] = useState('');
    const [AreaName, setAreaName] = useState('');
    const [UnitNumber, setUnitNumber] = useState('');
    const [propertyname, setPropertyName] = useState('');
    const [mobilenumber, setMobileNumber] = useState('');

    console.log(UnitNumber, propertyname, mobilenumber, "hgdshsjgjhasj");


    const [areaDetails, setAreaDetails] = useState('');
    console.log(areaDetails, "areaDetails");


    const navigate = useNavigate();

    console.log(loading, "loading");

    const [serviceTypeDetails, setServiceTypeDetails] = useState('');
    const [serviceTypeDetails1, setServiceTypeDetails1] = useState('');
    console.log(serviceTypeDetails1, serviceTypeDetails, "serviceTypeDetails");

    const propertyTypeMapping = {
        "STUDIO": "Studio",
        "ONE BHK": "1 BHK",
        "TWO BHK": "2 BHK",
        "THREE BHK": "3 BHK",
        "FOUR BHK": "4 BHK",
        "FIVE BHK": "5 BHK",
        "TWO BHK VILLA": "2 BHK VILLA",
        "THREE BHK VILLA": "3 BHK VILLA",
        "FOUR BHK VILLA": "4 BHK VILLA",
        "FIVE BHK VILLA": "5 BHK VILLA",
        "SIX BHK VILLA": "6 BHK VILLA",
    };

    const [unitOption, setUnitOption] = useState([])
    console.log(unitOption, "unitOption");

    const AreaDropdownFun = () => {
        axios.get(`${process.env.React_App_Url}/api/v1/area/dropdown/list`, {
            headers: {
                authorization: `Bearer ${userData?.accessToken}`,
            },
        })
            .then((response) => {
                setAreaDetails(response?.data);
                toast.success(response.data, { position: 'top-right' });
            })
            .catch((error) => {
                toast.error(error.response.data);
            });
    };

    useEffect(() => {
        AreaDropdownFun()
    }, [])

    const AreaOptions = areaDetails?.data?.map((item) => ({
        label: item?.name,
        value: item?.uuid,
    })) || [];


    const HandleChangeDate = (event) => {
        setDateValue(event?.target?.value)
    }

    const HandleChangeTime = (event) => {
        setTimeValue(event?.target?.value)
    }

    const HandleChangeAddress = (event) => {
        setAddressValue(event?.target?.value)
    }

    const HandleChangeArea = (event) => {
        console.log(event, "gdjsfjdsgjashdsahkdj");
        setAreaName(event?.value)
    }

    const HandleChangeUnitNumber = (event) => {
        setUnitNumber(event?.target?.value)
    }

    const HandleChangePropertyName = (event) => {
        setPropertyName(event?.target?.value)
    }

    const HandleChangeMobile = (event) => {
        setMobileNumber(event?.target?.value)
    }

    const today = new Date().toISOString().split('T')[0];

    const [material, setMaterial] = useState([])
    const [OtherSubType, setOtherSubType] = useState([])

    const [PriceValue, setPriceValue] = useState();
    const [TypeUUID, setTypeUUId] = useState();
    const [priceDetails, setPriceDetails] = useState("");
    console.log(priceDetails, "priceDetails");


    const [Typevalue, setTypeValue] = useState('')

    console.log(TypeUUID, "gjfjdshfkjdshkjd");


    const handleChangeType = (data) => {
        const UnitOptions = [];
        serviceTypeDetails1?.map((item) => {
            if (item?.type === data?.value) {
                console.log(item, "ghdfghgdjsdgdfgdsjfh");
                const formattedLabel = propertyTypeMapping[item?.property_type?.replaceAll('_', ' ').toUpperCase()] || item?.property_type?.replaceAll('_', ' ').toUpperCase();
                UnitOptions.push({
                    label: formattedLabel,
                    value: item?.uuid,
                });
            }
        });
        setUnitOption(UnitOptions);
        setTypeValue(data?.value)
        // setState1({ ...state1, mat: data });
    };

    const HandleChangeOtherType = (data) => {
        const OtherOptions = [];
        serviceTypeDetails1?.map((item) => {
            if (item?.type === data?.value) {
                OtherOptions?.push({
                    label: item?.other_cleaning_service_size,
                    value: item?.uuid
                });
                setUnitOption(OtherOptions);
            }
        })
    }


    useEffect(() => {
        //Massage
        if (serviceTypeDetails1) {
            if (ServiceUUID === "ST-8CB12A96") {
                const UnitOptions = [];
                serviceTypeDetails1?.length > 0 && serviceTypeDetails1?.map((item) => {
                    UnitOptions.push({
                        label: item?.time_hours + " : " + item?.time_minutes,
                        value: item?.uuid,
                    });
                });
                setUnitOption(UnitOptions);
            }
            // Maintainance
            else if (ServiceUUID === "ST-B85C9AAE") {
                const UnitOptions = [];
                serviceTypeDetails1?.length > 0 && serviceTypeDetails1?.map((item) => {
                    UnitOptions.push({
                        label: item?.item,
                        value: item?.uuid,
                    });
                });
                setUnitOption(UnitOptions);
            }
            // Others
            else if (ServiceUUID === "ST-015C1370") {
                const UnitOptions = [];
                serviceTypeDetails1?.length > 0 && serviceTypeDetails1?.map((item) => {
                    UnitOptions.push({
                        label: item?.other_cleaning_service_size,
                        value: item?.uuid,
                    });
                });
                setUnitOption(UnitOptions);
            }
            // Home and Office Cleaning  (Home Clean and Deep Clean Types)
            else {
                if (ServiceUUID === "ST-261AFDA6") {
                    if (SubServices !== "SST-652BD0FF" && SubServices !== "SST-BC908C34") {
                        const UnitOptions = [];
                        serviceTypeDetails1?.length > 0 && serviceTypeDetails1?.map((item) => {
                            const formattedLabel = propertyTypeMapping[item?.property_type?.replaceAll('_', ' ').toUpperCase()] || item?.property_type?.replaceAll('_', ' ').toUpperCase();
                            UnitOptions.push({
                                label: formattedLabel,
                                value: item?.uuid,
                            });
                        });
                        setUnitOption(UnitOptions);
                    }
                }
                else if (ServiceUUID === "ST-781D99B6") {
                    if (SubServices !== "SST-557F1B31" && SubServices !== "SST-3441CE44") {
                        const UnitOptions = [];
                        serviceTypeDetails1?.length > 0 && serviceTypeDetails1?.map((item) => {
                            UnitOptions.push({
                                label: item?.office_size_in_sqft,
                                value: item?.uuid,
                            });
                        });
                        setUnitOption(UnitOptions);
                    }
                }
            }
        }
    }, [ServiceUUID, SubServices, serviceTypeDetails1])

    // const handleChangeUnit = (dat) => {
    //     console.log(dat, "fbdnvdfsbchjdbfjdsh")
    //     serviceTypeDetails1?.map((item) => {
    //         if (item?.property_type === dat?.value) {
    //             // setPriceValue({ ...PriceValue, price_uuid: item?.uuid });
    //             setPriceValue(item?.uuid)
    //             setPriceDetails(item)
    //         }
    //         // else {
    //         //     setPriceValue(dat?.value)
    //         //     serviceTypeDetails1?.map((item) => {
    //         //         setPriceValue(item?.uuid)
    //         //         setPriceDetails(item)
    //         //     });
    //         // }
    //     });
    //     // setState1({ ...state1, utype: dat });
    // };

    const handleChangeUnit = (dat) => {
        // Massage
        if (ServiceUUID === "ST-8CB12A96") {
            serviceTypeDetails1?.map((item) => {
                if (item?.uuid === dat) {
                    // setState({ ...state, price_uuid: item?.uuid });
                    // setState1({ ...state1, utype: item?.uuid });
                    setPriceValue(item?.uuid)
                    setTypeUUId(item?.uuid)
                    setPriceDetails(item);
                }
            });
        }
        // Maintainance
        else if (ServiceUUID === "ST-B85C9AAE") {
            serviceTypeDetails1?.map((item) => {
                if (item?.uuid === dat) {
                    // setState({ ...state, price_uuid: item?.uuid });
                    // setState1({ ...state1, utype: item?.uuid });
                    setPriceValue(item?.uuid)
                    setTypeUUId(item?.uuid)
                    setPriceDetails(item);
                }
            });
        }
        // Others
        else if (ServiceUUID === "ST-015C1370") {
            serviceTypeDetails1?.map((item) => {
                if (item?.uuid === dat) {
                    // setState({ ...state, price_uuid: item?.uuid });
                    // setState1({ ...state1, utype: item?.uuid });
                    setPriceValue(item?.uuid)
                    setTypeUUId(item?.uuid)
                    setPriceDetails(item);
                }
            });
        }
        // Remaining
        else {
            // Home and Office Cleaning  (Home Clean and Deep Clean Types)
            if (ServiceUUID === "ST-261AFDA6") {
                if (SubServices !== "SST-652BD0FF" && SubServices !== "SST-BC908C34") {
                    serviceTypeDetails1?.some((item) => {
                        if (item?.uuid === dat) {
                            // setState({ ...state, price_uuid: item?.uuid });
                            setPriceValue(item?.uuid)
                            setPriceDetails(item);
                            return true; // This will stop the loop
                        }
                        return false; // Continue to the next item if no match
                    });
                    // setState1({ ...state1, utype: dat });
                    setTypeUUId(dat)
                }
                else {
                    serviceTypeDetails1?.some((item) => {
                        if (item?.uuid === dat && item?.type === Typevalue) {
                            // setState({ ...state, price_uuid: item?.uuid });
                            setPriceValue(item?.uuid)
                            setPriceDetails(item);
                            return true; // This will stop the loop
                        }
                        return false; // Continue to the next item if no match
                    });
                    // setState1({ ...state1, utype: dat });
                    setTypeUUId(dat)
                }
            }
            if (ServiceUUID === "ST-781D99B6") {
                if (SubServices !== "SST-557F1B31" && SubServices !== "SST-3441CE44") {
                    serviceTypeDetails1?.some((item) => {
                        if (item?.uuid === dat) {
                            // setState({ ...state, price_uuid: item?.uuid });
                            setPriceValue(item?.uuid)
                            setPriceDetails(item);
                            return true; // This will stop the loop
                        }
                        return false; // Continue to the next item if no match
                    });
                    // setState1({ ...state1, utype: dat });
                    setTypeUUId(dat)
                }
                else {
                    serviceTypeDetails1?.some((item) => {
                        if (item?.uuid === dat && item?.type === Typevalue) {
                            // setState({ ...state, price_uuid: item?.uuid });
                            setPriceValue(item?.uuid)
                            setPriceDetails(item);
                            return true; // This will stop the loop
                        }
                        return false; // Continue to the next item if no match
                    });
                    // setState1({ ...state1, utype: dat });
                    setTypeUUId(dat)
                }
            }
        }
    };

    const SubServiceTypeFunction = () => {
        axios.get(`${process.env.React_App_Url}/api/v1/home/serviceDetailsByServiceAndSubService?service_uuid=${location?.state?.data1}&sub_service_uuid=${location?.state?.data?.uuid}`, {
            headers: {
                authorization: `Bearer ${userData?.accessToken}`,
            },
        },
        )
            .then((response) => {
                console.log(response, "asdasdasd");
                setServiceTypeDetails(response?.data)
                setServiceTypeDetails1(response?.data?.data)
                toast.success(response.data, { position: 'top-right' });

                const dupArray = [];
                if (SubServices === "SST-557F1B31" || SubServices === "SST-3441CE44" || SubServices === "SST-652BD0FF" || SubServices === "SST-BC908C34") {
                    response?.data?.data?.map((item) => {
                        const value = dupArray?.some((re) => re?.value === item?.type);
                        if (!value) {
                            dupArray?.push({ label: item?.type?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char?.toUpperCase()), value: item?.type });
                        }
                    });
                    setMaterial(dupArray);
                }

                const OtherArray = [];
                if (ServiceUUID === "ST-015C1370") {
                    response?.data?.data?.map((item) => {
                        const value = OtherArray?.some((re) => re?.value === item?.type);
                        if (!value) {
                            OtherArray?.push({ label: item?.type?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char?.toUpperCase()), value: item?.type })
                        }
                    });
                    setOtherSubType(OtherArray)
                }
            })
            .catch((error) => {
                console.log(error, "sdaassda");
                toast.error(error.response.data);
            })
    }

    useEffect(() => {
        SubServiceTypeFunction()
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const payload = {
            consumer_uuid: userData?.user?.uuid,
            service_uuid: location?.state?.data1,
            sub_service_uuid: location?.state?.data?.uuid,
            price_uuid: PriceValue,
            area_uuid: AreaName,
            address: addressValue,
            booking_date: datevalue,
            booking_time: timevalue,
            unit_number: UnitNumber,
            property_name: propertyname,
            phone: mobilenumber
        };

        axios.post(`${process.env.React_App_Url}/api/v1/b2c/booking/create`, payload, {
            headers: {
                authorization: `Bearer ${userData?.accessToken}`,
            },
        })
            .then((response) => {
                console.log(response, "asdasdasd");
                toast.success(response?.data?.message, { position: 'top-right' });
                if (response?.data?.success === true) {
                    Swal.fire({
                        icon: 'success',
                        title: response.data?.message,
                        text: response.data?.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/B2CBookngList')
                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error, "sdaassda");
                toast.error(error.response.data);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <div className="main-wrapper ">
                <Header />

                <div className="page-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <h1 className="mb-0">Booking</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content ">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="row m-0 p-0 align-items-center justify-content-center">
                            <h3 className="text-center fw-bold">BOOK YOUR PACKAGE</h3>
                            <p className="text-center">
                                Experience Something New Every Moment
                            </p>
                        </div>
                        <div className="col-xl-10 col-lg-12 col-md-12 mt-5">
                            <div className="row ">
                                <div className="col-md-12">
                                    <div
                                        className="settings-widget"
                                        style={{
                                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                        }}
                                    >
                                        <div className="settings-inner-blk p-0">
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row mx-5">
                                                            {SubServices === "SST-557F1B31" ||
                                                                SubServices === "SST-3441CE44" ||
                                                                SubServices === "SST-652BD0FF" ||
                                                                SubServices === "SST-BC908C34" ? (
                                                                <div className="col-md-6 col-item">
                                                                    <div className="form-group">
                                                                        <label htmlFor="address">Type</label>
                                                                        <div className="search-group">
                                                                            <Select
                                                                                className=" select"
                                                                                name="sellist1"
                                                                                options={material}
                                                                                placeholder="Choose"
                                                                                onChange={handleChangeType}
                                                                                // value={AreaName}
                                                                                styles={style}
                                                                            ></Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (ServiceUUID === "ST-015C1370" ?
                                                                <div className="col-md-6 col-item">
                                                                    <div className="form-group">
                                                                        <label htmlFor="address">Type</label>
                                                                        <div className="search-group">
                                                                            <Select
                                                                                className=" select"
                                                                                name="sellist1"
                                                                                options={OtherSubType}
                                                                                placeholder="Choose"
                                                                                onChange={HandleChangeOtherType}
                                                                                // value={AreaName}
                                                                                styles={style}
                                                                            ></Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ""
                                                            )}
                                                            {/* {location?.state?.data1 === "ST-8CB12A96" || location?.state?.data1 === "ST-B85C9AAE" ? */}
                                                            <div className="col-md-6 col-item">
                                                                <div className="form-group">
                                                                    <label htmlFor="address">Unit Size</label>
                                                                    <div className="search-group">
                                                                        <Select
                                                                            className="select"
                                                                            name="sellist1"
                                                                            options={unitOption}
                                                                            placeholder="Choose"
                                                                            onChange={(item) => handleChangeUnit(item.value)}
                                                                            // value={AreaName}
                                                                            styles={style}
                                                                        ></Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {location?.state?.data1 === "ST-8CB12A96" ||
                                                                location?.state?.data1 === "ST-B85C9AAE" ||
                                                                location?.state?.data1 === "ST-B85C9AAE" ? (
                                                                ""
                                                            ) : (
                                                                <div className="col-md-6 col-item">
                                                                    <div className="form-group select-form mb-0">
                                                                        <label htmlFor="property">
                                                                            No. of Staff
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="address"
                                                                            placeholder="Enter No. of Staff"
                                                                            value={priceDetails?.number_of_staff}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {location?.state?.data1 === "ST-B85C9AAE" ||
                                                                location?.state?.data1 === "ST-8CB12A96" ||
                                                                location?.state?.data1 === "ST-B85C9AAE" ? (
                                                                ""
                                                            ) : (
                                                                <div className="col-md-6 col-item">
                                                                    <div className="form-group select-form mb-0">
                                                                        <label htmlFor="property">Duration</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="address"
                                                                            placeholder="Enter Duration"
                                                                            value={(priceDetails?.time_hours === undefined ? "" : priceDetails?.time_hours) + " : " + (priceDetails?.time_minutes === undefined ? "" : priceDetails?.time_minutes)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="col-md-6 col-item">
                                                                <div className="form-group select-form mb-0">
                                                                    <label htmlFor="property">Charges</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="address"
                                                                        placeholder="Enter Charges"
                                                                        value={priceDetails?.price}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-item">
                                                                <div className="form-group select-form mb-0">
                                                                    <label htmlFor="property">
                                                                        Property Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="address"
                                                                        placeholder="Enter Property Name"
                                                                        value={propertyname}
                                                                        onChange={HandleChangePropertyName}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-item">
                                                                <div className="form-group select-form mb-0">
                                                                    <label htmlFor="property">Unit No.</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="address"
                                                                        placeholder="Enter Unit No."
                                                                        value={UnitNumber}
                                                                        onChange={HandleChangeUnitNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-item">
                                                                <div className="form-group">
                                                                    <label htmlFor="address">Area</label>
                                                                    <div className="search-group">
                                                                        <Select
                                                                            className=" select"
                                                                            name="sellist1"
                                                                            options={AreaOptions}
                                                                            placeholder="Choose"
                                                                            onChange={HandleChangeArea}
                                                                            // value={AreaName}
                                                                            styles={style}
                                                                        ></Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-item">
                                                                <div className="form-group select-form mb-0">
                                                                    <label htmlFor="property">Address</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="address"
                                                                        placeholder="Enter Address"
                                                                        value={addressValue}
                                                                        onChange={HandleChangeAddress}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-item">
                                                                <div className="form-group select-form mb-0">
                                                                    <label htmlFor="property">Phone No</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="address"
                                                                        placeholder="Enter Phone No."
                                                                        value={mobilenumber}
                                                                        onChange={HandleChangeMobile}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-6 col-item">
                                                                <div className="form-group select-form mb-0">
                                                                    <label htmlFor="property">Date</label>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        id="address"
                                                                        placeholder="Enter Date"
                                                                        onChange={HandleChangeDate}
                                                                        value={datevalue}
                                                                        min={today}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-6 col-item">
                                                                <div className="form-group select-form mb-0">
                                                                    <label htmlFor="property">Time</label>
                                                                    <input
                                                                        type="time"
                                                                        className="form-control"
                                                                        id="address"
                                                                        placeholder="Enter Time"
                                                                        onChange={HandleChangeTime}
                                                                        value={timevalue}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-md-12 col-item mt-3 mb-4 mt-4"
                                                            style={{ marginLeft: "46%" }}
                                                        >
                                                            <button type="submit" className="btn btn-primary">
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NewBookingForm;

import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InstructorSidebar from "../sidebar";
import Header from "../../header/index-two";
import { Footer } from "../../footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment'

export default function BookngList() {

  const userData = JSON.parse(localStorage.getItem('userData'));

  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);
  const [BookingData, setBookingData] = useState('')
  const [BookingStatusupdate, setBookingStatusUpdate] = useState('')
  console.log(BookingStatusupdate, "BookingData");

  useEffect(() => {
    BookingListURL()
  }, [tabIndex])

  const BookingListURL = () => {

    let type;
    if (tabIndex === 0) {
      type = 'pending';
    } else if (tabIndex === 1) {
      type = 'completed';
    } else if (tabIndex === 2) {
      type = 'ongoing';
    } else if (tabIndex === 3) {
      type = 'cancelled';
    }

    axios.get(`${process.env.React_App_Url}/api/v1/frontend/booking/list?page=0&limit=10&search=&type=${type}`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    },
    )
      .then((response) => {
        console.log(response, "asdasdasd");
        setBookingData(response?.data?.data);
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      })
  }
  const formatPropertyType = (event) => {
    return event.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
  }

  const BookingStatusUpdate = (event) => {
    console.log(event, "fgdhdgsfsh");

    const payload = {
      "booking_uuid": event?.uuid,
      "booking_status": "cancelled"
    }

    axios.post(`${process.env.React_App_Url}/api/v1/frontend/bookingStatusUpdate`, payload, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    },
    )
      .then((response) => {
        console.log(response, "asdasdasd");
        setBookingStatusUpdate(response?.data?.data);
        Swal.fire({
          icon: 'success',
          title: response.data?.message,
          text: response.data?.message,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/BookngList')
          }
        });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        Swal.fire({
          icon: 'error',
          title: error.response.data?.message,
          text: error.response.data?.message,
        })
      })
  }

  return (
    <div className="main-wrapper">
      <Header />
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu="BookingList" />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>Booking Lists</h3>
                      </div>
                      <div className="row mx-3 mt-4">
                        <div className="col-md-12">
                          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                            <TabList>
                              <Tab>Pending Booking</Tab>
                              <Tab>Completed Booking</Tab>
                              <Tab>Ongoing Booking</Tab>
                              <Tab>Cancel Booking</Tab>

                            </TabList>

                            <TabPanel>
                              <div className="col-xl-12 col-lg-12 col-md-12 mt-4">
                                {BookingData?.data?.length <= 0 && <strong>No Records Found</strong>}
                                {BookingData?.data?.length > 0 && BookingData?.data?.map((items, index) => (
                                  <div className="row" key={index}>
                                    <div className="col-md-12">
                                      {/* Property Type Card */}
                                      <div className="settings-widget">
                                        <Accordion >
                                          <Accordion.Item eventKey={`${index}0`}>
                                            <Accordion.Header>
                                              <div className="row">
                                                <p>
                                                Booking No :  <strong >{items?.uuid}</strong>, &nbsp; &nbsp;&nbsp; &nbsp; Booking Date : <strong>{`${moment(items?.booking_date).format("DD-MM-YYYY")}`}</strong>,  &nbsp; &nbsp; &nbsp; &nbsp; Area : <strong>{items?.areas}</strong>
                                                </p>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Property Type: <span style={{ color: "green" }}>{formatPropertyType(items?.type_of_property)}</span></strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>King Bed: <span>{items?.king_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Single Bed: <span>{items?.single_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Sofa Bed: <span>{items?.sofa_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Total Bed: <span>{items?.total_number_of_beds}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Arrival Time: <span>{items.arrival_time || "N/A"}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Exit Time: <span>{items.exit_time}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Service Type: <span style={{ color: "green" }}>Cleaning</span></strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN: <span>{items?.van_number}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name: <span>{items?.driver_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner: <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor: <span>{items?.supervisor_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details: <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes: <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Others</strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service: <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge: <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out: <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status: <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Descriptions: <span>N/A</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-12 col-item mt-3 mb-4 ml-2">
                                                <button type="submit" className="btn btn-primary" onClick={() => BookingStatusUpdate(items)}>
                                                  Cancel
                                                </button>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          {/* <Accordion.Item eventKey={`${index}1`}>
                                            <Accordion.Header>
                                              <strong>Service Type: <span style={{ color: "#222" }}>Cleaning</span></strong>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN: <span>{items?.van_number}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name: <span>{items?.driver_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner: <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor: <span>{items?.supervisor_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details: <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes: <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          <Accordion.Item eventKey={`${index}2`}>
                                            <Accordion.Header>
                                              <strong>Others</strong>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service: <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge: <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out: <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status: <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Describtions: <span>N/A</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item> */}
                                        </Accordion>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className="col-xl-12 col-lg-12 col-md-12 mt-4">
                                {BookingData?.data?.length <= 0 && <strong>No Records Found</strong>}
                                {BookingData?.data?.length > 0 && BookingData?.data?.map((items, index) => {
                                  return <div className="row" key={index}>
                                    <div className="col-md-12">
                                      <div className="settings-widget">
                                        <Accordion >
                                          <Accordion.Item eventKey={`${index}0`}>
                                            <Accordion.Header>
                                              <div className="row">
                                              <p>
                                                Booking No :  <strong >{items?.uuid}</strong>, &nbsp; &nbsp;&nbsp; &nbsp; Booking Date : <strong>{`${moment(items?.booking_date).format("DD-MM-YYYY")}`}</strong>,  &nbsp; &nbsp; &nbsp; &nbsp; Area : <strong>{items?.areas}</strong>
                                                </p>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Property Type: <span style={{ color: "green" }}>{formatPropertyType(items?.type_of_property)}</span></strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>King Bed: <span>{items?.king_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Single Bed: <span>{items?.single_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Sofa Bed: <span>{items?.sofa_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Total Bed: <span>{items?.total_number_of_beds}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Arrival Time: <span>{items.arrival_time || "N/A"}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Exit Time: <span>{items.exit_time}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Service Type: <span style={{ color: "green" }}>Cleaning</span></strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN: <span>{items?.van_number}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name: <span>{items?.driver_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner: <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor: <span>{items?.supervisor_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details: <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes: <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Others</strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service: <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge: <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out: <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status: <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Descriptions: <span>N/A</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          {/* <Accordion.Item eventKey={`${index}1`}>
                                            <Accordion.Header>
                                              <strong>Service Type: <span style={{ color: "#222" }}>Cleaning</span></strong>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN: <span>{items?.van_number}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name: <span>{items?.driver_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner: <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor: <span>{items?.supervisor_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details: <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes: <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          <Accordion.Item eventKey={`${index}2`}>
                                            <Accordion.Header>
                                              <strong>Others</strong>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service: <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge: <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out: <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status: <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Describtions: <span>N/A</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item> */}
                                        </Accordion>
                                        {/* <div className="settings-inner-blk p-0">
                                          <div className="sell-course-head comman-space">
                                            <strong>Property Type : <span style={{ color: "white" }}>{formatPropertyType(items?.type_of_property)}</span></strong>
                                          </div>
                                          <div className="row mx-4">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>King Bed : <span>{items?.king_bed}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Single Bed : <span>{items?.single_bed}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Sofa Bed : <span>{items?.sofa_bed}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Total Bed : <span>{items?.total_number_of_beds}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Arrival Time  : <span>{items.arrival_time || "N/A"}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Exit Time  : <span>{items.exit_time}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </div>
                                        </div> */}
                                      </div>
                                      {/* 
                                      <div className="settings-widget">
                                        <div className="settings-inner-blk p-0">
                                          <div className="sell-course-head comman-space">
                                            <strong>Service Type : <span style={{ color: "white" }}>Cleaning</span></strong>
                                          </div>
                                          <div className="row mx-4">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN : <span>{items?.van_number}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name : <span>{items?.driver_name}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner : <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor : <span>{items?.supervisor_name}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details  : <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes  : <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="settings-widget">
                                        <div className="settings-inner-blk p-0">
                                          <div className="sell-course-head comman-space">
                                            <strong>Others</strong>
                                          </div>
                                          <div className="row mx-4">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service : <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge : <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out : <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status : <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Describtions  : <span>N/A</span></p>
                                                          </div>

                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                    </div>
                                    {/* <div className="col-md-12 col-item mt-3 mb-4 ml-2">
                                      <button type="submit" className="btn btn-primary">
                                        Cancel
                                      </button>
                                    </div> */}
                                  </div>
                                })}
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className="col-xl-12 col-lg-12 col-md-12 mt-4">
                                {BookingData?.data?.length <= 0 && <strong>No Records Found</strong>}
                                {BookingData?.data?.length > 0 && BookingData?.data?.map((items, index) => {
                                  return <div className="row" key={index}>
                                    <div className="col-md-12">
                                      <div className="settings-widget">
                                        {/* <div className="settings-inner-blk p-0">
                                          <div className="sell-course-head comman-space">
                                            <strong>Property Type : <span style={{ color: "white" }}>{formatPropertyType(items?.type_of_property)}</span></strong>
                                          </div>
                                          <div className="row mx-4">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>King Bed : <span>{items?.king_bed}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Single Bed : <span>{items?.single_bed}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Sofa Bed : <span>{items?.sofa_bed}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Total Bed : <span>{items?.total_number_of_beds}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Arrival Time  : <span>{items.arrival_time || "N/A"}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Exit Time  : <span>{items.exit_time}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </div>
                                        </div> */}
                                        <Accordion >
                                          <Accordion.Item eventKey={`${index}0`}>
                                            <Accordion.Header>
                                              <div className="row">
                                              <p>
                                                Booking No :  <strong >{items?.uuid}</strong>, &nbsp; &nbsp;&nbsp; &nbsp; Booking Date : <strong>{`${moment(items?.booking_date).format("DD-MM-YYYY")}`}</strong>,  &nbsp; &nbsp; &nbsp; &nbsp; Area : <strong>{items?.areas}</strong>
                                                </p>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Property Type: <span style={{ color: "green" }}>{formatPropertyType(items?.type_of_property)}</span></strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>King Bed: <span>{items?.king_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Single Bed: <span>{items?.single_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Sofa Bed: <span>{items?.sofa_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Total Bed: <span>{items?.total_number_of_beds}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Arrival Time: <span>{items.arrival_time || "N/A"}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Exit Time: <span>{items.exit_time}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Service Type: <span style={{ color: "green" }}>Cleaning</span></strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN: <span>{items?.van_number}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name: <span>{items?.driver_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner: <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor: <span>{items?.supervisor_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details: <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes: <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Others</strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service: <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge: <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out: <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status: <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Description: <span>N/A</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          {/* <Accordion.Item eventKey={`${index}1`}>
                                            <Accordion.Header>
                                              <strong>Service Type: <span style={{ color: "#222" }}>Cleaning</span></strong>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN: <span>{items?.van_number}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name: <span>{items?.driver_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner: <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor: <span>{items?.supervisor_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details: <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes: <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          <Accordion.Item eventKey={`${index}2`}>
                                            <Accordion.Header>
                                              <strong>Others</strong>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service: <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge: <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out: <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status: <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Describtions: <span>N/A</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item> */}
                                        </Accordion>
                                      </div>

                                      {/* <div className="settings-widget">
                                        <div className="settings-inner-blk p-0">
                                          <div className="sell-course-head comman-space">
                                            <strong>Service Type : <span style={{ color: "white" }}>Cleaning</span></strong>
                                          </div>
                                          <div className="row mx-4">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN : <span>{items?.van_number}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name : <span>{items?.driver_name}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner : <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor : <span>{items?.supervisor_name}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details  : <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes  : <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="settings-widget">
                                        <div className="settings-inner-blk p-0">
                                          <div className="sell-course-head comman-space">
                                            <strong>Others</strong>
                                          </div>
                                          <div className="row mx-4">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service : <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge : <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out : <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status : <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Describtions  : <span>N/A</span></p>
                                                          </div>

                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                    </div>
                                    {/* <div className="col-md-12 col-item mt-3 mb-4 ml-2">
                                      <button type="submit" className="btn btn-primary">
                                        Cancel
                                      </button>
                                    </div> */}
                                  </div>
                                })}
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className="col-xl-12 col-lg-12 col-md-12 mt-4">
                                {BookingData?.data?.length <= 0 && <strong>No Records Found</strong>}
                                {BookingData?.data?.length > 0 && BookingData?.data?.map((items, index) => {
                                  return <div className="row" key={index}>
                                    <div className="col-md-12">
                                      <div className="settings-widget">
                                        {/* <div className="settings-inner-blk p-0">
                                          <div className="sell-course-head comman-space">
                                            <strong>Property Type : <span style={{ color: "white" }}>{formatPropertyType(items?.type_of_property)}</span></strong>
                                          </div>
                                          <div className="row mx-4">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>King Bed : <span>{items?.king_bed}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Single Bed : <span>{items?.single_bed}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Sofa Bed : <span>{items?.sofa_bed}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Total Bed : <span>{items?.total_number_of_beds}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Arrival Time  : <span>{items.arrival_time || "N/A"}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Exit Time  : <span>{items.exit_time}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </div>
                                        </div> */}
                                        <Accordion >
                                          <Accordion.Item eventKey={`${index}0`}>
                                            <Accordion.Header>
                                              <div className="row">
                                              <p>
                                                Booking No :  <strong >{items?.uuid}</strong>, &nbsp; &nbsp;&nbsp; &nbsp; Booking Date : <strong>{`${moment(items?.booking_date).format("DD-MM-YYYY")}`}</strong>,  &nbsp; &nbsp; &nbsp; &nbsp; Area : <strong>{items?.areas}</strong>
                                                </p>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Property Type: <span style={{ color: "green" }}>{formatPropertyType(items?.type_of_property)}</span></strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>King Bed: <span>{items?.king_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Single Bed: <span>{items?.single_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Sofa Bed: <span>{items?.sofa_bed}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Total Bed: <span>{items?.total_number_of_beds}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Arrival Time: <span>{items.arrival_time || "N/A"}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Exit Time: <span>{items.exit_time}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Service Type: <span style={{ color: "green" }}>Cleaning</span></strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN: <span>{items?.van_number}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name: <span>{items?.driver_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner: <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor: <span>{items?.supervisor_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details: <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes: <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ marginTop: '10px' }}>
                                                <strong>Others</strong>
                                              </div>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service: <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge: <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out: <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status: <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Descriptions: <span>N/A</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          {/* <Accordion.Item eventKey={`${index}1`}>
                                            <Accordion.Header>
                                              <strong>Service Type: <span style={{ color: "#222" }}>Cleaning</span></strong>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN: <span>{items?.van_number}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name: <span>{items?.driver_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner: <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor: <span>{items?.supervisor_name}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details: <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes: <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                          <Accordion.Item eventKey={`${index}2`}>
                                            <Accordion.Header>
                                              <strong>Others</strong>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className="row mx-4">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service: <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge: <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out: <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status: <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Describtions: <span>N/A</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item> */}
                                        </Accordion>
                                      </div>

                                      {/* <div className="settings-widget">
                                        <div className="settings-inner-blk p-0">
                                          <div className="sell-course-head comman-space">
                                            <strong>Service Type : <span style={{ color: "white" }}>Cleaning</span></strong>
                                          </div>
                                          <div className="row mx-4">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>VAN : <span>{items?.van_number}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Driver Name : <span>{items?.driver_name}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Cleaner : <span>{items?.cleaner?.map(item => item?.full_name).join(', ')}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Supervisor : <span>{items?.supervisor_name}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Check Details  : <span>{items?.check_in_details}</span></p>
                                                          </div>
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Notes  : <span>{items?.notes}</span></p>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="settings-widget">
                                        <div className="settings-inner-blk p-0">
                                          <div className="sell-course-head comman-space">
                                            <strong>Others</strong>
                                          </div>
                                          <div className="row mx-4">
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="row mt-3">
                                                    <div className="col-md-12">
                                                      <form>
                                                        <div className="row">
                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Additional Service : <span>{items?.additional_service_deatils?.length > 0 ? 'Yes' : 'No'}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Charge : <span style={{ color: "green" }}>{items?.additional_service_charge}</span> Inclusive of VA</p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Time In/out : <span>{items?.time_in}/{items?.time_out}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Status : <span style={{ color: "red" }}>{items?.payment_status}</span></p>
                                                          </div>

                                                          <div className="col-xs-12 col-md-4 col-item">
                                                            <p>Job Describtions  : <span>N/A</span></p>
                                                          </div>

                                                        </div>
                                                      </form>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>


                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                    </div>
                                    {/* <div className="col-md-12 col-item mt-3 mb-4 ml-2">
                                      <button type="submit" className="btn btn-primary">
                                        Cancel
                                      </button>
                                    </div> */}
                                  </div>
                                })}
                              </div>
                            </TabPanel>
                          </Tabs>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <Footer />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import servicesCardImg2 from "../../assets/img/home/services-card-2.jpg";

import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../header/index-two";
import { Footer } from "../footer";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loaderGif from "../../assets/img/home/loader.gif";
import ContentData from "./subServicesContentData";
export const HomeCleaningService = () => {
  useEffect(() => {
    document.body.className = "home-two";

    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  const location = useLocation();
  console.log(location, "ghdsvsgajsa");

  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const [serviceTypeDetails, setServiceTypeDetails] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [content, setContent] = useState(null);

  console.log(serviceTypeDetails, "serviceTypeDetails");

  const SubServiceTypeFunction = () => {
    setIsLoader(true);
    axios
      .get(
        `${process.env.React_App_Url}/api/v1/admin/subserviceListForDropdown/${location?.state?.uuid}`,
        {
          headers: {
            authorization: `Bearer ${userData?.accessToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response, "asdasdasd");
        setServiceTypeDetails(response?.data);
        setIsLoader(false);
        toast.success(response.data, { position: "top-right" });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      });
  };

  const handleContent = () => {
    const uuid = location?.state?.uuid;
    const trimmedUuid = uuid.replace(/-/g, "");
    const content = ContentData[trimmedUuid].subContent;
    setContent(content);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    scrollToTop();
  }, [location.state]);

  useEffect(() => {
    SubServiceTypeFunction();
    handleContent();
  }, [location.state]);

  const RedirectToSubServicepage = (event) => {
    navigate("/BookingForm", {
      state: {
        data: event,
        data1: location?.state?.uuid,
      },
    });
  };

  return (
    <>
      <div className="main-wrapper">
        <Header />
        {/* Home banner */}
        <div className="page-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h1 className="mb-0">{location.state.label}</h1>
              </div>
            </div>
          </div>
        </div>

        {/**page content */}
        {isLoader ? (
          <div className="h-100 w-100 mb-5 mt-5 d-flex align-items-center justify-content-center">
            <img
              src={loaderGif}
              alt=""
              style={{ height: "100px", width: "100px" }}
            />
          </div>
        ) : (
          <div
            className="container d-flex justify-content-center align-items-center flex-column"
            style={{ marginTop: "60px" }}
          >
            <div className="ux-design-five w-100">
              <div className="row justify-content-center">
                {serviceTypeDetails?.data?.length > 0 &&
                  serviceTypeDetails?.data?.map((items, index) => {
                    return (
                      <div
                        key={index}
                        className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mt-3 mb-3"
                      >
                        <div
                          className="rounded-4 m-0 p-2 pb-4 border border-1"
                          style={{
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                        >
                          <div className="course-box-five d-flex align-items-center justify-content-center"
                            style={{ background: 'linear-gradient(to right, rgba(219, 168, 55, 0.8), rgba(255, 217, 107, 0.5), rgba(219, 168, 55, 0.8))', }}
                          >
                            <img
                              src={items?.image_url}
                              alt="Course"
                              crossOrigin="anonymous"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "auto",
                                opacity: 0.3,
                                // zIndex: -1,
                              }}
                            />
                            < div className="product-five h-100 w-100"></div>
                            {/* Ovelay button */}
                            <div className="joing-course-ovelay">
                              <button
                                onClick={() => RedirectToSubServicepage(items)}
                                className="joing-course-btn"
                              >
                                Book Now
                              </button>
                            </div>
                            {/* Ovelay button */}
                          </div>
                          <div className="w-100 mt-0 p-0 d-flex align-items-center justify-content-center">
                            <p
                              className=" mb-0 text-center text-dark text-center w-auto  fw-bold"
                              style={{
                                fontSize: "14px",
                                // textTransform: "uppercase",
                              }}
                            >
                              {items?.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            {/** Content */}

            <div className="row m-0 p-0 mt-5">
              {Array.isArray(content) &&
                content.map((item, index) => (
                  <div className="row m-0 p-0" key={index}>
                    <div className="col-12">
                      <h4
                        className="fw-semibold text-capitalize"
                        style={{ textAlign: "" }}
                      >
                        {item.heading}{" "}
                      </h4>
                      <p
                        className="text-secondary"
                        style={{ textAlign: "justify" }}
                      >
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
            </div>

            {/** Content */}
          </div>
        )}
        {/**page content */}

        {/* Footer */}
        <Footer />
        {/* Footer */}
      </div >
    </>
  );
};
export default HomeCleaningService;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Select from "react-select";
// import Icon1 from "../../assets/img/contactUs/icon1.jpeg";
// import Icon2 from "../../assets/img/contactUs/icon2.jpeg";
// import Icon3 from "../../assets/img/contactUs/icon3.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../header/index-two";
import { Footer } from "../footer";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const options = [
//   { label: "Select Category", value: "Category" },
//   { label: "Category One", value: "Category1" },
//   { label: "Category Two", value: "Category2" },
// ];

export const SubServices = () => {
    useEffect(() => {
        document.body.className = "home-two";

        return () => {
            document.body.className = "";
        };
    }, []);

    useEffect(() => {
        AOS.init({ duration: 1200, once: true });
    }, []);

    const location = useLocation();
    console.log(location, "ghdfjs");

    const navigate = useNavigate();

    const userData = JSON.parse(localStorage.getItem('userData'));

    const [serviceTypeDetails, setServiceTypeDetails] = useState('');
    console.log(serviceTypeDetails, "serviceTypeDetails");

    const SubServiceTypeFunction = () => {
        axios.get(`${process.env.React_App_Url}/api/v1/home/serviceDetailsByServiceAndSubService?service_uuid=${location?.state?.data1}&sub_service_uuid=${location?.state?.data?.uuid}`, {
            headers: {
                authorization: `Bearer ${userData?.accessToken}`,
            },
        },
        )
            .then((response) => {
                console.log(response, "asdasdasd");
                setServiceTypeDetails(response?.data)
                toast.success(response.data, { position: 'top-right' });
            })
            .catch((error) => {
                console.log(error, "sdaassda");
                toast.error(error.response.data);
            })
    }

    useEffect(() => {
        SubServiceTypeFunction()
    }, [])

    // const formatPropertyType = (event) => {
    //     return event?.replace(/_/g, ' ').replace(/\b\w/g, char => char?.toUpperCase())
    // }

    const formatPropertyType = (type) => {
        const propertyTypeMapping = {
            "studio": "Studio",
            "one_bhk": "1 BHK",
            "two_bhk": "2 BHK",
            "three_bhk": "3 BHK",
            "four_bhk": "4 BHK",
            "five_bhk": "5 BHK",
            "two_bhk_villa": "2 BHK VILLA",
            "three_bhk_villa": "3 BHK VILLA",
            "four_bhk_villa": "4 BHK VILLA",
            "five_bhk_villa": "5 BHK VILLA",
            "office": "6 BHK VILLA",
        };

        return propertyTypeMapping[type?.toLowerCase()] || type?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char?.toUpperCase());
    };

    const HandleBookingForm = (event) => {
        navigate("/BookingForm", {
            state: {
                data: event,
                data1: location?.state?.data1,
                data2: location?.state?.data?.uuid
            }
        })
    }

    return (
        <>
            <div className="main-wrapper">
                <Header />
                {/* Home banner */}


                <div className="container d-flex justify-content-center align-items-center" style={{ marginTop: "60px" }}>
                    <div className="ux-design-five col-12">
                        <div className="row justify-content-center">
                            {/* Col */}
                            {serviceTypeDetails?.data?.length > 0 && serviceTypeDetails?.data?.map((items, index) => {
                                return <div key={index} className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                    <div className="course-box-five" >
                                        <div className="product-five">
                                            {/* Product image */}
                                            <div className="d-flex heading mb-3 flex-column align-items-center">
                                                {/* <div className="product-img-card">
                                                <img className="" alt="icon" src={Icon1} style={{ width: "80px", height: "auto" }} />
                                            </div>
                                            <br /> */}
                                                {/* Product Content */}
                                                <div className="w-100 d-flex justify-content-center mt-2">
                                                    <strong className="product-five-title text-white text-lg">
                                                        {formatPropertyType(items?.type)}
                                                    </strong>
                                                </div>
                                                {location?.state?.data1 === "ST-8CB12A96" || location?.state?.data1 === "ST-B85C9AAE" ?
                                                    <>
                                                        <div className="w-100 d-flex justify-content-center">
                                                            <span style={{ fontSize: "12px", color: "white" }}>
                                                                Price : {items?.price}
                                                            </span>
                                                        </div>
                                                        <div className="w-100 d-flex justify-content-center">
                                                            <span style={{ fontSize: "12px", color: "white" }}>
                                                                Duration : {items?.time_hours}:{items?.time_minutes} Minutes
                                                            </span>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="w-100 d-flex justify-content-center">
                                                            <span style={{ fontSize: "12px", color: "white" }}>
                                                                Price : {items?.price}
                                                            </span>
                                                        </div>
                                                        <div className="w-100 d-flex justify-content-center">
                                                            <span style={{ fontSize: "12px", color: "white" }}>
                                                                Duration : {items?.time_hours}:{items?.time_minutes} Minutes
                                                            </span>
                                                        </div>
                                                        <div className="w-100 d-flex justify-content-center">
                                                            <span style={{ fontSize: "12px", color: "white" }}>
                                                                Property Type : {formatPropertyType(items?.property_type)}
                                                            </span>
                                                        </div>
                                                        <div className="w-100 d-flex justify-content-center">
                                                            <span style={{ fontSize: "12px", color: "white" }}>
                                                                No of Staff : {items?.number_of_staff}
                                                            </span>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        {/* Ovelay button */}
                                        <div className="joing-course-ovelay">
                                            {/* <Link to="/Booking" className="joing-course-btn"> */}
                                            <button onClick={() => HandleBookingForm(items)} className="joing-course-btn">
                                                Book Now
                                            </button>
                                        </div>
                                        {/* Ovelay button */}
                                    </div>
                                </div>
                            })}
                            {/* Repeat the same structure for other columns */}
                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="course-box-five" style={{ padding: "30px", height: "90%", backgroundColor: "#b89205" }}>
                                    <div className="product-five">
                                        <div className="d-flex heading mb-3 flex-column align-items-center">
                                            <div className="w-100 d-flex justify-content-center mt-2">
                                                <strong className="product-five-title text-white text-lg">
                                                    Without Materials
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="joing-course-ovelay">
                                        <Link to="/Booking" className="joing-course-btn">
                                            Book Now
                                        </Link>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* Favorite Course */}

                {/* Footer */}
                <Footer />
                {/* Footer */}
            </div >
        </>
    );
};
export default SubServices

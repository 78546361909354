const ContentData = {
  ST261AFDA6: {
    label: "Home Cleaning Services",
    type: "Home Cleaning Services",
    subContent: [
      {
        heading: "HOME CLEANING PACKAGE WITH CLEANING MATERIALS AND EQUIPMENT",
        description:
          "Our comprehensive Home Cleaning Package includes everything you need to maintain a spotless home. We provide high-quality cleaning materials and state-of-the-art equipment to ensure every corner of your living space is thoroughly cleaned. Our package covers all aspects of home cleaning, from dusting and vacuuming to deep cleaning kitchens and bathrooms. With our professional-grade tools and eco-friendly products, you can enjoy a clean and healthy home environment with minimal effort. Whether you&apos;re preparing for a special occasion or need regular maintenance, our Home Cleaning Package guarantees satisfaction and peace of mind.",
      },
      {
        heading: "Home Cleaning Hourly Rate",
        description:
          "At Elite Superstar, we offer flexible Home Cleaning services at an affordable hourly rate, allowing you to tailor our services to fit your specific needs and budget. Our professional cleaners are available to handle a variety of tasks, from light tidying to intensive deep cleaning, ensuring every corner of your home is spotless. With our hourly rate option, you have the freedom to decide how much time you want our team to spend on each area of your home. Whether it&apos;s a quick cleanup before guests arrive or a thorough cleaning session for the entire house, our skilled cleaners use high-quality materials and equipment to deliver exceptional results.",
      },
      {
        heading: "Deep Cleaning Per Hour Rate",
        description:
          "Experience the ultimate in cleanliness with Elite Superstar's Deep Cleaning services, available at a competitive per-hour rate. Our deep cleaning option is perfect for those times when a standard clean just isn't enough. Whether it's after a party, before a move, or simply to maintain a high standard of hygiene, our professional cleaners are equipped with top-quality materials and advanced equipment to tackle even the toughest grime and dirt.",
      },
      {
        heading:
          "Home Deep Cleaning Package with Cleaning Materials and Equipment",
        description:
          "Discover the ultimate clean with Elite Superstar's Home Deep Cleaning Package. This all-inclusive service provides everything you need for a thorough and meticulous deep clean of your entire home. Our package includes high-quality cleaning materials and advanced equipment to ensure every surface, nook, and cranny is impeccably cleaned. Our experienced cleaning professionals tackle the most challenging cleaning tasks, from scrubbing and disinfecting bathrooms and kitchens to dusting and vacuuming hard-to-reach areas. We use eco-friendly products and state-of-the-art tools to ensure a safe and effective cleaning process, leaving your home not just clean, but truly revitalized.",
      },
      {
        heading: "Hotel-At-Home Cleaning Service",
        description:
          "Bring the luxury and pristine cleanliness of a five-star hotel to your home with Elite Superstar's Hotel-At-Home Cleaning Service. Our premium service is designed to provide you with an exceptional cleaning experience that mirrors the meticulous standards of top-tier hotels.Our professional cleaners use high-quality cleaning materials and advanced equipment to deliver a spotless and hygienic environment. From perfectly made beds and polished surfaces to meticulously cleaned bathrooms and kitchens, every detail is attended to with precision and care.",
      },
      {
        heading: "Sanitation and Disinfection",
        description:
          "Ensure a safe and hygienic environment with Elite Superstar's Sanitation and Disinfection services. Our expert team uses hospital-grade disinfectants and advanced techniques to eliminate germs, viruses, and bacteria from your home or workplace. We target high-touch surfaces and hard-to-reach areas, ensuring thorough and effective disinfection. Ideal for routine maintenance or emergency situations, our service provides peace of mind with a clean and sanitized space. Trust Elite Superstar to protect your health and well-being with our comprehensive sanitation and disinfection solutions.",
      },
      {
        heading: "Odor Removal",
        description:
          "Eliminate unpleasant smells and refresh your space with Elite Superstar's Odor Removal service. Our expert team uses advanced techniques and eco-friendly products to identify and neutralize odor sources, ensuring a fresh and clean environment. Whether it's pet odors, smoke, mold, or any other persistent smell, we effectively target and remove them, leaving your home or workplace smelling pleasant and inviting. Trust Elite Superstar to restore the air quality and comfort of your space with our professional odor removal solutions.",
      },
    ],
  },
  ST781D99B6: {
    label: "Office Cleaning Services",
    type: "Office Cleaning Services",
    subContent: [
      {
        heading: "Office Cleaning Per Hour Rate",
        description:
          "Experience top-tier cleanliness and convenience with Elite Superstar's Office Cleaning Per Hour Rate service. Our flexible hourly rates allow you to customize our professional cleaning services to fit your specific office needs and budget. Our skilled cleaners use high-quality materials and advanced equipment to ensure a spotless and healthy work environment. Whether you need daily upkeep or occasional deep cleaning, our per-hour rate service provides the perfect solution for maintaining a pristine office. Choose Elite Superstar for reliable, efficient, and thorough office cleaning tailored to your schedule.",
      },
      {
        heading: "Office Deep Cleaning Per Hour Rate",
        description:
          "Ensure your workspace is immaculate with Elite Superstar's Office Deep Cleaning Per Hour Rate service. Our expert cleaners utilize high-quality materials and advanced equipment to thoroughly clean and sanitize your office, targeting hard-to-reach areas and high-touch surfaces. With our flexible hourly rates, you can customize the cleaning duration to meet your specific needs and budget. Whether it's for routine deep cleaning or a one-time intensive clean, our service guarantees a pristine and healthy work environment. Trust Elite Superstar for exceptional office deep cleaning that fits your schedule.",
      },
      {
        heading:
          "Office Cleaning Package with Cleaning Materials and Equipment",
        description:
          "Experience a spotless and professional workspace with Elite Superstar's Office Cleaning Package. This all-inclusive service provides everything you need for comprehensive office cleaning, including high-quality materials and advanced equipment. Our skilled cleaners ensure every area of your office is meticulously cleaned, from workstations and meeting rooms to kitchens and restrooms. We use eco-friendly products and the latest cleaning technologies to maintain a healthy and inviting work environment. Whether you require regular maintenance or a one-time deep clean, our Office Cleaning Package offers reliable and thorough service tailored to your needs. Trust Elite Superstar to keep your office looking its best.",
      },
      {
        heading:
          "Office Deep Cleaning Package with Cleaning Materials and Equipment",
        description:
          "Transform your workspace with Elite Superstar's Office Deep Cleaning Package. This comprehensive service includes all necessary cleaning materials and advanced equipment to ensure a thorough and meticulous clean. Our professional cleaners tackle every corner of your office, from workstations and common areas to restrooms and kitchens, using eco-friendly products and cutting-edge techniques. Ideal for quarterly deep cleans or preparing for important events, our package guarantees a pristine and hygienic environment. Trust Elite Superstar to deliver exceptional results, providing a cleaner, healthier, and more inviting office space.",
      },
      {
        heading: "Sanitation and Disinfection",
        description:
          "Ensure a safe and hygienic environment with Elite Superstar's Sanitation and Disinfection services. Our expert team uses hospital-grade disinfectants and advanced techniques to eliminate germs, viruses, and bacteria from your home or workplace. We target high-touch surfaces and hard-to-reach areas, ensuring thorough and effective disinfection. Ideal for routine maintenance or emergency situations, our service provides peace of mind with a clean and sanitized space. Trust Elite Superstar to protect your health and well-being with our comprehensive sanitation and disinfection solutions.",
      },
      {
        heading: "Odor Removal",
        description:
          "Eliminate unpleasant smells and refresh your space with Elite Superstar's Odor Removal service. Our expert team uses advanced techniques and eco-friendly products to identify and neutralize odor sources, ensuring a fresh and clean environment. Whether it's pet odors, smoke, mold, or any other persistent smell, we effectively target and remove them, leaving your home or workplace smelling pleasant and inviting. Trust Elite Superstar to restore the air quality and comfort of your space with our professional odor removal solutions.",
      },
      {
        heading: "Sanitation and Disinfection with Odor Removal",
        description:
          "Create a safe, healthy, and fresh environment with Elite Superstar's Sanitation and Disinfection with Odor Removal services. Our professional team utilizes hospital-grade disinfectants and advanced techniques to eliminate germs, viruses, and bacteria from your home or workplace. We focus on high-touch surfaces and hard-to-reach areas for comprehensive disinfection. Additionally, we tackle unpleasant odors at their source using eco-friendly products, ensuring your space smells clean and inviting. Perfect for routine maintenance or emergency situations, this service provides peace of mind with a thoroughly sanitized and fresh-smelling space. Trust Elite Superstar for complete sanitation, disinfection, and odor removal solutions.",
      },
    ],
  },
  ST015C1370: {
    label: "Other Cleaning Services",
    type: "Other Cleaning Services",
    subContent: [
      {
        heading: "Mattress Cleaning Service",
        description:
          "Ensure a healthy sleep environment with Elite Superstar's Mattress Cleaning Service. Our professional cleaners use advanced techniques and eco-friendly products to deep clean and sanitize your mattress, removing dust mites, allergens, and stains. Experience a fresher, more comfortable sleep with our thorough and effective mattress cleaning service.",
      },
      {
        heading: "Carpet Cleaning Service",
        description:
          "Revitalize your carpets with Elite Superstar's Carpet Cleaning Service. Our expert team uses high-quality equipment and eco-friendly cleaning solutions to remove dirt, stains, and allergens, leaving your carpets looking and feeling like new. Whether it's a routine clean or a deep stain removal, trust us to deliver exceptional results.",
      },
      {
        heading: "Sofa Cleaning Service",
        description:
          "Restore the beauty and comfort of your sofa with Elite Superstar's Sofa Cleaning Service. Our skilled cleaners use specialized tools and safe cleaning products to eliminate dirt, stains, and odors from your upholstery. Enjoy a fresh, clean, and inviting sofa with our professional cleaning service.",
      },
      {
        heading: "Curtain Cleaning Service",
        description:
          "Keep your curtains looking their best with Elite Superstar's Curtain Cleaning Service. Our expert team uses gentle yet effective cleaning methods to remove dust, allergens, and stains, preserving the fabric and ensuring your curtains are fresh and clean. Trust us for a thorough and careful curtain cleaning experience.",
      },
    ],
  },
  ST8CB12A96: {
    label: "Massage Service",
    type: "Massage Service",
    subContent: [
      {
        heading: "Full Body Massage (Swedish / Deep Tissue)",
        description:
          "Indulge in relaxation with Elite Superstar's Full Body Massage, available in Swedish or Deep Tissue styles. Our Swedish massage uses gentle, flowing strokes to promote relaxation and improve circulation, while our Deep Tissue massage targets deeper layers of muscle to relieve tension and pain. Whether you seek soothing relaxation or targeted muscle relief, our skilled therapists provide a personalized experience to meet your needs.",
      },
      {
        heading: "Full Body Massage Shiatsu Combination (Dry or with Oil)",
        description:
          "Experience the best of both worlds with our Full Body Shiatsu Combination Massage. This service blends traditional Shiatsu techniques with optional oil application for a deeply relaxing and therapeutic experience. Shiatsu involves applying pressure to specific points along the body's energy pathways, enhancing relaxation and restoring balance. Choose between a dry massage or one with oil for added comfort and relaxation.",
      },
      {
        heading: "Foot Massage Reflexology",
        description:
          "Pamper your feet with Elite Superstar's Foot Massage Reflexology. This specialized treatment targets pressure points on the feet that correspond to different organs and systems in the body. Our skilled therapists use techniques designed to relieve stress, improve circulation, and promote overall well-being. Enjoy a deeply relaxing and revitalizing foot massage that benefits both body and mind.",
      },
    ],
  },
  STB85C9AAE: {
    label: "Maintenance",
    type: "Maintenance",
    subContent: [
      {
        heading: "HVAC Duct Cleaning",
        description:
          "Improve your indoor air quality with Elite Superstar's HVAC Duct Cleaning service. Our expert team removes dust, debris, and contaminants from your HVAC system's ductwork, ensuring cleaner air and more efficient system performance. Regular duct cleaning helps reduce allergens, enhances airflow, and prolongs the lifespan of your HVAC system. Trust us for a thorough and professional duct cleaning that benefits your health and comfort.",
      },
      {
        heading: "HVAC Deep Cleaning",
        description:
          "Optimize your HVAC system's efficiency with Elite Superstar's HVAC Deep Cleaning service. This comprehensive service includes the detailed cleaning of all components, such as the coils, fans, and filters, in addition to the ductwork. Our advanced techniques and equipment remove built-up dirt and grime, improving system performance and extending its lifespan. Ensure a healthier indoor environment and enhanced energy efficiency with our thorough HVAC deep cleaning.",
      },
    ],
  },
};

export default ContentData;

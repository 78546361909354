import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import InstructorSidebar from "../sidebar";
import Header from "../../header/index-two";
import { Footer } from "../../footer";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function InstructorNotification() {

  const userData = JSON.parse(localStorage.getItem('userData'));

  const [NotificationData, setNotificationData] = useState('')
  console.log(NotificationData, "NotificationData");

  useEffect(() => {
    NotificationListURL()
  }, [])

  const NotificationListURL = () => {

    axios.get(`${process.env.React_App_Url}/api/v1/user/NotificationList?page=0&limit=10`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    },
    )
      .then((response) => {
        console.log(response, "asdasdasd");
        setNotificationData(response?.data?.data);
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      })
  }

  return (
    <div className="main-wrapper">
      <Header />
      {/* Notification */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu={"InstructorNotification"} />
            {/* Sidebar */}

            {/* Notifications */}
            <div className="col-xl-9 col-md-8">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  {NotificationData?.length > 0 ? NotificationData?.map((items) => {
                    <div className="profile-heading">
                      <h3>{items?.title}</h3>
                      <p>{items?.message}</p>
                    </div>
                  }) :
                    <React.Fragment>
                      <div>
                        <span style={{ textAlign: 'center', verticalAlign: 'middle', marginLeft: "40%" }}>No Records Found</span>
                      </div>
                    </React.Fragment>
                  }
                  {/* <div className="checkout-form personal-address secure-alert border-line">
                    <div className="personal-info-head">
                      <h4>Security Alerts</h4>
                      <p>
                        You will get only those email notification what you
                        want.
                      </p>
                    </div>
                    <div className="form-check form-switch check-on">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked
                      />
                      <label className="form-check-label">
                        Email me whenever encounter unusual activity
                      </label>
                    </div>
                    <div className="form-check form-switch check-on">
                      <input className="form-check-input" type="checkbox" />
                      <label className="form-check-label">
                        Email me if new browser is used to sign in
                      </label>
                    </div>
                  </div> */}

                  {/* <div className="un-subscribe">
                    <Link href="#" className="btn btn-danger">
                      Unsubscribe from all of the above
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>

            {/* Notifications */}
          </div>
        </div>
      </div>
      {/* Notification */}
      <Footer />
    </div >
  );
}

import React, { useState, useEffect } from "react";
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Phone,
  // MessageCircle,
  Mail,
} from "react-feather";
import { FaWhatsapp } from "react-icons/fa";
import { GrLocation } from "react-icons/gr";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import Companylogo from "../../assets/img/contactUs/companylogo.png";

export const Footer = () => {
  const navigate = useNavigate();

  const [serviceTypeDetails, setServiceTypeDetails] = useState("");
  console.log(serviceTypeDetails, "serviceTypeDetails");

  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log(userData, "jdffdgshfdskjhkjds");

  const ServiceTypeFunction = () => {
    axios
      .get(
        `${process.env.React_App_Url}/api/v1/service/type/listForb2cClient`,
        {
          headers: {
            authorization: `Bearer ${userData?.accessToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response, "asdasdasd");
        setServiceTypeDetails(response?.data);
        toast.success(response.data, { position: "top-right" });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      });
  };
  const RedirectToSubservice = (event) => {
    if (userData === null) {
      navigate("/login");
    } else {
      navigate("/HomeCleaning", { state: event });
    }
  };

  useEffect(() => {
    ServiceTypeFunction();
  }, []);
  return (
    <footer className="footer footer-two">
      <div className="footer-top" style={{ backgroundColor: "#0E0E0D" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-about">
                <div className="footer-logo">
                  <img src={Companylogo} alt="logo" />
                  <p
                    className="m-0 p-0 mt-4"
                    style={{
                      color: "white",
                      fontSize: "22px",
                      fontWeight: "bold",
                    }}
                  >
                    Elite Superstar
                  </p>
                </div>
                <div className="footer-about-content ">
                  <p
                    className="m-0 p-0"
                    style={{ color: "white", textAlign: "justify" }}
                  >
                    At Elite Superstar, we’re more than just a cleaning service
                    – we’re your reliable partner in maintaining a clean,
                    organized, and welcoming environment. With a commitment to
                    excellence and a passion for cleanliness, we strive to
                    exceed your expectations with every visit.
                  </p>
                </div>
                <div className="social-icon">
                  <ul>
                    <li>
                      <Link to="#" target="_blank">
                        <Facebook size={14} />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Twitter size={14} />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Instagram size={14} />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        <Linkedin size={14} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu footer-menu-two">
                <h2 className="footer-title" style={{ color: "white" }}>
                  Service We Provide
                </h2>
                <ul>
                  {serviceTypeDetails?.data?.length > 0 &&
                    serviceTypeDetails?.data?.map((items, index) => (
                      <li
                        onClick={() => RedirectToSubservice(items)}
                        key={index}
                      >
                        <Link style={{ color: "white" }}>{items?.label}</Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-contact footer-contact-two">
                <h2 className="footer-title" style={{ color: "white" }}>
                  Address
                </h2>
                <div className="footer-contact-widget">
                  <div className="footer-address mb-3">
                    <h6 style={{ color: "white" }}>Location</h6>
                    <div className="d-flex gap-3 align-items-center">
                      <GrLocation
                        className="w-auto fs-1"
                        style={{ color: "#DBA837" }}
                      />
                      <p style={{ color: "white" }} className="mb-0">
                        {" "}
                        Office 401, Sheikha Noura Tower, Barsha Heights, Dubai,
                        UAE. ,
                      </p>
                    </div>
                  </div>

                  <h6 style={{ color: "white" }}>Email Address</h6>
                  <div className="d-flex gap-3 align-items-center flex-row mb-3">
                    <Mail
                      className="w-auto fs-4"
                      style={{ color: "#DBA837" }}
                    />
                    <p className="w-auto mb-0">
                      <Link
                        to="mailto:dreamslms@example.com"
                        style={{ color: "white" }}
                      >
                        eliteSuperstar@example.com
                      </Link>
                    </p>
                  </div>

                  <div className="row m-0 p-0">
                    <div className="col-6 m-0 p-0">
                      <h6 style={{ color: "white" }} className="w-auto">
                        {" "}
                        Phone number
                      </h6>
                      <div className="d-flex gap-3 align-items-center flex-row">
                        <Phone
                          className="w-auto fs-4"
                          style={{ color: "#DBA837" }}
                        />
                        <p className="mb-0">
                          <Link to="tel:042977554" style={{ color: "white" }}>
                            042977554
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="col-6 m-0 p-0">
                      <h6 className="" style={{ color: "white" }}>
                        Whatsapp number
                      </h6>
                      <div className="d-flex gap-3 align-items-center flex-row">
                        <FaWhatsapp
                          className="w-auto fs-4"
                          style={{ color: "#DBA837" }}
                        />
                        <p className="mb-0">
                          <Link
                            to="tel:050 614 3135"
                            style={{ color: "white" }}
                          >
                            050 614 3135
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom" style={{ backgroundColor: "#0E0E0D" }}>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="copyright-text">
                  <p className="mb-0" style={{ color: "white" }}>
                    &copy; 2024 Elite Superstar. All rights reserved.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="privacy-policy">
                  <ul>
                    <li>
                      <Link to=" " style={{ color: "white" }}>
                        Terms & Condition
                      </Link>
                    </li>
                    <li>
                      <Link to=" " style={{ color: "white" }}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to=" " style={{ color: "white" }}>
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

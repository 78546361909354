import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./aboutUs.css";

import { Footer } from "../footer";
import Header from "../header/index-two";
import cleaningImg1 from "../../assets/img/home/cleaning-img-1.jpg";
import cleaningImg2 from "../../assets/img/home/cleaning-img-2.jpg";
import { mission } from "../imagepath";
import { vision, Pattern01 } from "../imagepath";
import { about } from "../imagepath";
import { Quote } from "../imagepath";

import { HomeRightBottom } from "../imagepath";
const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);
  return (
    <>
      <Header />
      <div className="main-wrapper">
        <div className="page-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h1 className="mb-0">About Us</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <section className="growup-section">
            <div
              className="home-two-shapes"
              style={{ right: "0", left: "2rem" }}
            >
              <img src={HomeRightBottom} alt="" />
            </div>
            <div className="container">
              <div className="row align-items-center">
                <div className=" col-12 wow fadeInLeft" data-aos="fade-left">
                  <div className="header-two-title">
                    <h2 className="text-navy text-center">
                      Elite Superstar, UAE
                    </h2>
                    <div
                      className="header-two-text w-100 d-flex flex-column align-items-center justify-content-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <p className="mb-2 text-center w-75">
                        Welcome to Elite Superstar Cleaning Services,
                        Dubai&apos;s premier provider of exceptional cleaning
                        solutions. Our mission is to deliver unparalleled
                        cleaning services that exceed the expectations of our
                        clients, ensuring their homes and workplaces are
                        spotless, hygienic, and welcoming.
                      </p>
                      <p className="mb-0 text-center w-75">
                        Elite Superstar Cleaning Services is a premier cleaning
                        company based in Dubai, renowned for its top-notch
                        cleaning solutions tailored to both residential and
                        commercial clients. The company offers a wide range of
                        services including deep cleaning, regular maintenance,
                        carpet cleaning, and window washing. With a team of
                        highly trained professionals and a commitment to using
                        eco-friendly products, Elite Superstar Cleaning Services
                        ensures a spotless and hygienic environment for its
                        clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="growup-section">
            <div className="home-two-shapes">
              <img src={HomeRightBottom} alt="" />
            </div>
            <div className="container">
              <div className="row align-items-center flex-row-reverse">
                <div
                  className="col-xl-6 col-lg-6 col-md-5 col-12"
                  data-aos="fade-right"
                >
                  <div className="growup-images-two">
                    <div className="growup-skills-img">
                      <img
                        className="content-section-img rounded-circle border-0"
                        src={cleaningImg1}
                        alt="image-banner"
                        title="image-banner"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-6 col-lg-6 col-md-7 col-12 wow fadeInLeft"
                  data-aos="fade-left"
                >
                  <div className="header-two-title">
                    <h2 className="text-navy">Who We Are</h2>
                    <div className="header-two-text">
                      <p className="mb-0">
                        Elite Superstar Cleaning Services was founded with a
                        vision to set new standards in the cleaning industry. We
                        have built a reputation for excellence through our
                        dedication to quality, professionalism, and customer
                        satisfaction. Our team of highly trained and experienced
                        cleaning professionals is committed to delivering
                        top-notch services tailored to the unique needs of each
                        client.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="growup-section">
            <div
              className="home-two-shapes"
              style={{ right: "0", left: "2rem" }}
            >
              <img src={HomeRightBottom} alt="" />
            </div>
            <div className="container">
              <div className="row align-items-center ">
                <div
                  className="col-xl-6 col-lg-6 col-md-5 col-12"
                  data-aos="fade-right"
                >
                  <div className="growup-images-two">
                    <div className="growup-skills-img">
                      <img
                        className="rounded-circle content-section-img border-0"
                        src={cleaningImg2}
                        alt="image-banner"
                        title="image-banner"
                        style={{ height: "500px", width: "500px" }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-6 col-lg-6 col-md-7 col-12 wow fadeInLeft"
                  data-aos="fade-left"
                >
                  <div className="header-two-title">
                    <h2 className="text-navy">Our Approach</h2>
                    <div className="header-two-text">
                      <p className="mb-0">
                        At Elite Superstar Cleaning Services, we believe that a
                        clean environment is essential for well-being and
                        productivity. Our approach combines meticulous attention
                        to detail with the use of high-quality, eco-friendly
                        products and the latest cleaning technologies. We tailor
                        our services to meet the specific requirements of each
                        client, ensuring a customized cleaning experience that
                        delivers outstanding results.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Master Skills Career */}
        <section className="master-skill-three">
          <div className="master-three-vector">
            <img className="ellipse-right img-fluid" src={Pattern01} alt="" />
          </div>
          <div className="container">
            <div className="row flex-row-reverse">
              <div
                className="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center align-items-center"
                data-aos="fade-right"
              >
                <div className="master-three-images">
                  <div className="master-three-left">
                    <img
                      className="img-fluid"
                      src={about}
                      alt="image-banner"
                      title="image-banner"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12" data-aos="fade-left">
                <div className="home-three-head" data-aos="fade-up">
                  <h2>Our Vision and Mission</h2>
                </div>
                <h5 className="text-blue">
                  Quality cleaning is our first priority
                </h5>

                <div className="py-4">
                  <img className="quote home3Slide" alt="" src={Quote} />
                </div>
                <div className="home-three-content" data-aos="fade-up">
                  <p>
                    Having a simplified, uncluttered home is a form of self
                    care.
                  </p>
                  <strong>~ Emma Scheib</strong>
                </div>
                <div className="career-group aos" data-aos="fade-up">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified">
                          <div className="mission-vision mb-3">
                            <img className="" src={mission} alt="" />
                          </div>
                          <h5>Our Mission</h5>
                          <p className="m-0">
                            To be the leading provider of exceptional cleaning
                            services in Dubai, setting new standards of
                            excellence and innovation in the industry. We aim to
                            create cleaner, healthier, and more beautiful
                            environments for our clients, contributing to their
                            well-being and enhancing their quality of life.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified">
                          <div className="mission-vision mb-3">
                            <img className="" src={vision} alt="" />
                          </div>
                          <h5>Our Vision</h5>
                          <p className="m-0">
                            Our mission is to deliver top-quality cleaning
                            solutions with unwavering commitment to customer
                            satisfaction, professionalism, and integrity.
                            Providing meticulous and reliable cleaning services
                            using the latest techniques and eco-friendly
                            products. Understanding and addressing the unique
                            needs of each client, offering personalized cleaning
                            plans and exceptional customer service.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Master Skills Career */}

        <Footer />
      </div>
    </>
  );
};

export default AboutUs;

import React, { useState, useEffect } from "react";
import B2CInstructorSidebar from "../B2CSidebar";
import { Footer } from "../../footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GoldMembership from "../../../assets/img/goldmembership.jpg"
import axios from "axios";
// import moment from "moment/moment";

export default function B2CRewards() {

    const userData = JSON.parse(localStorage.getItem('userData'));

    const [RewardsData, setRewardsData] = useState('');
    console.log(RewardsData, "RewardsData");

    useEffect(() => {
        RewardsListURL();
    }, []);

    const RewardsListURL = () => {
        axios.get(`${process.env.React_App_Url}/api/v1/frontend/rewards/${userData?.user?.uuid}`, {
            headers: {
                authorization: `Bearer ${userData?.accessToken}`,
            },
        })
            .then((response) => {
                console.log(response, "asdasdasd");
                setRewardsData(response?.data?.data);
                toast.success(response.data, { position: 'top-right' });
            })
            .catch((error) => {
                console.log(error, "sdaassda");
                toast.error(error.response.data);
            });
    };

    return (
        <div className="main-wrapper">
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <B2CInstructorSidebar activeMenu="B2CRewards" />
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sell-course-head comman-space">
                                        <h3>Rewards</h3>
                                    </div>
                                    <div className="settings-widget">
                                        <div
                                            className="col-md-12"
                                            style={{
                                                backgroundImage: `url(${GoldMembership})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                width: "74%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: "#000", // Text color
                                                textAlign: "center",
                                                padding: "30px",
                                                marginLeft: "115px"
                                            }}
                                        >
                                            <div style={{ marginTop: "30%" }}>
                                                {/* <h2 style={{ fontWeight: 'bold', marginBottom: '10px' }}>GOLD MEMBERSHIP CARD</h2> */}
                                                <p style={{ marginTop: "0px", fontSize: "30px" }}><b>Name: {userData?.user?.name}</b></p>
                                                <p style={{ marginTop: "-19px", fontSize: "30px" }}><b>Total Points: {RewardsData?.data?.reward_points} Points = {RewardsData?.data?.reward_amount} AED</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
